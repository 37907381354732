import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { apiURL, siteUrl } from "../../api/backend";

function TextEditor(props) {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js"
        onBlur={(html) => {
          // console.log('html', editorRef.current.getContent());
          props.onChange(props.index, editorRef.current.getContent());
        }}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={props.content}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | image link | table | " +
            "formatselect bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          height: "400",
          images_upload_handler: function (blobInfo, success, failure) {
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            fetch(apiURL + "/upload_image", {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((result) => {
                success(siteUrl + result.location);
              })
              .catch((error) => {
                failure("Image upload failed: " + error.message);
              });
          },
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}

export default TextEditor;
