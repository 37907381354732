import React from "react";
import CodeEditorPackage from "@uiw/react-textarea-code-editor";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../api/backend";

const CodeEditor = (props) => {
  // console.log('propssss', props)
  const code = props.content;
  const [disabled, setDisabled] = React.useState(
    !(getCookie("userToken") && !useLocation().search.length)
  );

  // if((getCookie('userToken')) || (!useLocation().search.length)){
  //   setDisabled(false);
  // }
  return (
    <div
    //style={{ display: 'grid', gridTemplateColumns: 'auto 50px' }}
    >
      <CodeEditorPackage
        // onChange={(e) => {props.onChange(props.id, e.target.value)}}
        disabled={disabled}
        value={code}
        language={props.codeProgrammingLanguage}
        placeholder="Please enter code."
        padding={15}
        style={{
          fontSize: 14,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "Source Code Pro,ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onBlur={(e) => {
          props.onChange(props.index, e.target.value);
        }}
      />
      {/* <select name="" id="" style={{ border: 'none' }}>
        <option value="php">php</option>
        <option value="js">js</option>
        <option value="bash">bash</option>
        <option value="c">c</option>
      </select> */}
    </div>
  );
};

export default CodeEditor;
