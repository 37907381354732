import { useState } from "react";
import TextEditor from "../Editor/TextEditor";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../api/backend";

const PageTextEditor = (props) => {
  const [showEditor, setShowEditor] = useState(false);
  const location = useLocation();
  // console.log("locaton", location, location.search);

  return (
    <div
      onClick={(e) => {
        if (e.detail === 3) {
          if (getCookie("userToken") && location.search === "") {
            showEditor ? setShowEditor(false) : setShowEditor(true);
          }
        }
      }}
    >
      {showEditor || props.content.length === 0 ? (
        <TextEditor
          onChange={props.onChange}
          index={props.index}
          id={props.id}
          content={props.content}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: props.content }}
          style={{ padding: "10px" }}
        />
      )}
    </div>
  );
};

export default PageTextEditor;
