import { Link, Redirect } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { getCookie } from "../api/backend";

const Home = () => {
  return (
    <div>
      <h3>Welcome to Note4</h3>
      <br />
      {!getCookie("userEmail") ? (
        <p>
          In order to use Note4, you have to login or register.
          <br />
          <br />
          <Stack spacing={2} direction="row">
            <Link className="link-button" to="/login">
              <Button variant="contained">Login</Button>
            </Link>
            <Link className="link-button" to="/register">
              <Button variant="outlined">Register</Button>
            </Link>
          </Stack>
        </p>
      ) : null}
      <br />
      <br />
      <h3>What is Note4?</h3>
      <br />
      <p>
        This is a place where you can save your texts and your codes with
        beautiful code editor and text editor.
      </p>
      <br />
      <br />
      <h3>Help:</h3>
      <br />
      <p>
        At first after login to the website you have make your first folder.
        click on the "+Add a folder" button on the left sidebar.
        <br />
        If you want to edit a text, click on the text. and after changes, don't
        forget to press the "save" button.
        <br />
        If you want to delete a text block, remove all the contents inside the
        block and then press the "save" button.
      </p>
      <br />
      <br />
      <h3>Contact us:</h3>
      <br />
      <p>
        If you found any bug or any new idea I'll be so happy if you send an
        email to me.
        <br />
        support@nordicstandard.net
      </p>
    </div>
  );
};

export default Home;
